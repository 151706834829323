import Vue from 'vue'
import App from './App.vue'
import './assets/iconfont/iconfont.css'
import dataV from '@jiaminghi/data-view'
import '@/utils/flexible'
import scroll from 'vue-seamless-scroll'
// 引入和风天气图标
import 'qweather-icons/font/qweather-icons.css'

Vue.config.productionTip = false
Vue.use(dataV)
Vue.use(scroll)

new Vue({
  render: h => h(App),
}).$mount('#app')

