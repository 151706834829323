<template>
  <div class="table">
    <div class="table_class" v-for="(item, index) in tableData" :key="index">
      <div class="tr_cls">
        <!-- <img :src="require(`@/assets/img/colleges/${item.logo}.png`)" alt="" /> -->
        <span v-if="!item.status"></span>
        <img :src="item.status ? require(`@/assets/img/colleges/${item.logo}.png`) : ''" alt="" v-if="item.status"/>
        {{ item.lib }}
      </div>
      <div class="tr_cls tr_clsT">
        {{ item.user_count.toLocaleString() }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}
.table_class {
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 98%;
  font-weight: 600;
  margin: auto;
  background-color: #0e4065;
  .tr_cls {
    width: 70%;
    padding: 4px 0;
    text-align: left;
    border-right: 3px solid #01284b;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-left: 25px;
    }
    span{
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-left: 50px;
    }
  }
  .tr_clsT {
    flex: 1;
    color: #83ec09;
    border-right: none;
    text-align: right;
    margin-right: 10px;
  }
}
.border_class {
  width: 5px;
  background-color: #142970;
}
.table_class:nth-child(2n) {
  background-color: #08264c;
}
</style>
