//导入请求配置文件
import request from "./axios.js";

//定义接口
//登录
let login = function (data) {
  return request.postURL("/member/xxxx", data);
};

//获取用户信息
let getUser = function (data) {
  return request.get("/api/web/lib/user", data);
};

let getBook= function (data) {
  return request.get("/api/web/lib/new/book", data);
};

let getCollection = function (data) {
  return request.get("/api/web/lib/collection", data);
};


let getBookRanking = function (data) {
  return request.get("/api/web/lib/book", data);
};

let getLoanvolume = function (data) {
  return request.get("api/web/lib/borrow", data);
};

export { login,  getUser, getCollection, getBook, getBookRanking,getLoanvolume };

