<template>
  <div class="headcom">
    <div class="head">
      <div class="head_left">
        <i :class="`qi-${weatcherData.icon}`" style="font-size: 30px; font-weight: 800"></i>
        <p class="head_left_title">{{ weatcherData.text }}</p>
        <p class="head_left_title">{{ weatcherData.temp }}℃</p>
        <p class="head_left_title">{{ timers }}</p>
      </div>
      <div class="head_title">天津高校数字图书馆联盟大数据系统</div>
      <div class="head_right">成员馆数据 >></div>
    </div>
    <div class="head_foot">
      <img src="../assets/img/head_foot_left.png" alt="" class="head_foot_left" />
      <img src="../assets/img/head_foot_right.png" alt="" class="head_foot_right" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      weatcherData: {},
      timers: "",
    };
  },
  created() {
    axios({
      method: "get",
      url: "https://api.qweather.com/v7/weather/now?location=101030900&key=cf90d76a93f94a26a7a937d105e06482",
    }).then(({ data }) => {
      if (data.code === "200") {
        this.weatcherData = data.now;
        this.timers = data.now.obsTime.split("T")[0].replace(/-/g, "/");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  align-items: center;
  margin-left: 50px;
  padding-top: 30px;
  .head_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20%;
    // .imgs {
    //   width: 80px;
    //   height: 80px;
    // }
    .head_left_title {
      font-size: 26px;
      color: #fff;
      font-weight: 550;
    }
  }
  .head_title {
    flex: 1;
    position: relative;
    left: 29%;
    transform: translate(-50%);
    color: #fff;
    font-weight: 600;
    font-size: 50px;
  }
  .head_right {
    font-size: 26px;
    color: #fff;
    font-weight: 550;
    margin-right: 60px;
  }
}
.head_foot {
  margin-top: -20px;
  .head_foot_right,
  .head_foot_left {
    width: 350px;
    height: 80px;
  }
}
</style>
