<template>
  <div class="bit">
    <!-- <div class="nulldiv"></div> -->
    <div class="topBox">
      <!-- <div style="width: 96%; padding: 15px;"> -->
      <div class="topBox2">
        <video
          ref="videoPlayer"
          src="https://readerstar2.oss-cn-beijing.aliyuncs.com/shutu/shutu.mp4"
          autoplay="autoplay"
          loop="loop"
          muted="muted"
          @loadeddata="playVideo"
        ></video>
      </div>
    </div>
    <div class="botBox">
      <div class="botBox2">
        <div class="botBox_fons">
          <div>
            成员馆借阅量
            <div class="fons1">
              <countTo :startVal="startVal" :endVal="endVal" :duration="3000"></countTo>
            </div>
          </div>
          <div class="fons2">
            <i class="iconfont icon-you-jiantou-1"></i>
            <i class="iconfont icon-you-jiantou-2"></i>
            <i class="iconfont icon-you-jiantou-3"></i>
            <i class="iconfont icon-you-jiantou-4"></i>
          </div>
          <div class="fons3">
            昨日借阅量
            <span>
              <countTo :startVal="startVal" :endVal="endVal2" :duration="3000"></countTo>
            </span>
          </div>
        </div>
        <!-- 柱状图 -->
        <div class="botBox_list" id="main"></div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import * as echarts from "echarts";
import { getLoanvolume } from "@/api/api";

export default {
  components: { countTo },
  data() {
    return {
      startVal: 0,
      endVal: 0,
      endVal2: 0,
    };
  },
  methods: {
    playVideo() {
      let vide = this.$refs.videoPlayer;
      vide.muted = 'muted';
      vide.play();
    },
    async drawLine() {
      let mite = []
      let data = []
      const res = await getLoanvolume() 
      if (res.status == 200) {
        this.endVal = res.data.total
        this.endVal2 = parseInt(res.data.yesterday_total)
        const arr = res.data.records.slice(1,res.data.records.length)
        arr.reverse()
        arr.forEach(item => {
          data.push(item.book_count)
          item.date = item.date.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
          mite.push(item.date)
        })
      }
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      myChart.setOption({
        grid: {
          x: 50,
          y: 25,
          x2: 20,
          y2: 25,
          borderWidth: 1,
        },
      });
      option = {
        xAxis: {
          type: "category",
          data: mite,
        },
        yAxis: {
          data: [],
          type: "value",
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#7e7f81",
              margin: 15,
            },
            // formatter: '{value} %'//y轴的每一个刻度值后面加上‘%’号
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            //y轴线的颜色以及宽度
            show: false,
            lineStyle: {
              color: "rgba(219,225,255,1)",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            //分割线配置
            show: true,
            lineStyle: {
              color: "#7e7f81",
            },
          },
        },
        series: [
          {
            data: data,
            type: "bar",
            barWidth: 25,
            color: new echarts.graphic.LinearGradient(
              /*第五个参数则是一个数组，用于配置颜色的渐变过程。 //前四个参数用于配置渐变色的起止位置，这四个参数依次对应 右下左上 四个方位。也就是从右边开始顺时针方向。 //通过修改前4个参数，可以实现不同的渐变方向
              每项为一个对象，包含offset和color两个参数
                */ 0,
              0,
              0,
              1,
              [
                {
                  //代表渐变色从正上方开始
                  offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
                  color: "rgb(255, 204, 0)",
                }, //柱图渐变色
                {
                  offset: 1, //指100%处的颜色
                  color: "rgb(243, 155, 2)",
                },
              ]
            ),
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    // 调用当前图表
    this.drawLine();
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.bit {
  width: 40%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  .nulldiv {
    // height: 1%;
  }
  .topBox {
    width: 100%;
    height: 53%;
    // background-color: #163873;
    background-image: url(../assets/img/videobg.png);
    background-size: 100% 100%;
    overflow: hidden;
    box-shadow: 0.1px 0.1px 2px 0.2px #1a52b3;
    margin-bottom: 28px;
    // align-content: center;
    position: relative;
    .topBox2 {
      width: 96.5%;
      height: 94%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background: #00ffff;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .botBox {
    // position: sticky;
    // bottom: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    // background-image: url(../assets/img/itembg.png);
    // background-size: 100% 100%;
    box-shadow: 0.1px 0.1px 2px 0.2px #1a52b3;
    // position: relative;
    .botBox2 {
      margin-top: 31px;
      padding: 8px;
      .botBox_fons {
        font-size: 22px;
        text-align: left;
        justify-content: space-between;
        margin-bottom: 1px;
        color: #089ee5;
        display: flex;
        font-weight: 600;
        .fons1 {
          font-size: 25px;
          color: #fe6c2b;
          font-weight: 900;
        }
        fons2 {
          i {
            font-size: 22px;
          }
        }
        .fons3 {
          width: 40%;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          vertical-align: middle;
          span {
            font-size: 21px;
            color: #94d535;
            font-weight: 900;
          }
        }
      }

      .botBox_list {
        height: 227px;
        width: 100%;
        background-color: #081e50;
        canvas {
        }
      }
    }
  }
}
</style>
