<template>
  <div class="bit">
    <div class="topBox">
      <div class="topBox2">
        <div class="topBox_fons">
          成员馆总馆藏量（册）
          <span class="fons1"
            ><countTo :startVal="startVal" :endVal="endVal" :duration="3000"></countTo
          ></span>
        </div>
        <div style="margin-top: 16px">
          <slider ref="sliders" :options="options">
            <slideritem v-for="(item, ind) in someListOn" :key="ind">
              <div class="item_list" v-for="(arr, index) in item" :key="index">
                <div class="span_name">{{ arr.cate }} {{ arr.cname }}</div>
                <div class="span_number">{{ arr.book_count }}</div>
              </div>
            </slideritem>
          </slider>
        </div>
      </div>
    </div>
    <div class="botBox">
      <div class="botBox2">
        <div class="botBox_fons">成员馆新书上架数量（月更新）</div>
        <div class="botBox_list">
          <slider ref="slider" :options="optionsTwo" v-if="listData.length > 1">
            <slideritem v-for="(item, index) in listData" :key="index">
              <rightBottom :listData="item" :isValue="isValue"></rightBottom>
            </slideritem>
          </slider>
          <rightBottom :listData="listData[0]" :isValue="isValue" v-else></rightBottom>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import { slider, slideritem } from "vue-concise-slider";
import rightBottom from "./rightCld/rightBottom.vue";
import { getCollection, getBook } from "@/api/api";
export default {
  components: {
    slider,
    slideritem,
    countTo,
    rightBottom,
  },
  data() {
    return {
      startVal: 0,
      endVal: 0,
      someListOn: [],
      isValue: true,
      options: {
        currentPage: 0,
        loop: true,
        thresholdTime: "400",
        autoplay: "5000",
        speed: 300,
        pagination: false,
      },
      optionsTwo: {
        currentPage: 0, //当前页码
        thresholdDistance: 500, //滑动判定距离
        thresholdTime: 100, //滑动判定时间
        // autoplay: 5000, //自动滚动[ms]
        loop: true, //循环滚动
        direction: "vertical", //方向设置，垂直滚动
        loopedSlides: 1, //无限滚动前后遍历数
        slidesToScroll: 1, //每次滑动项数
        timingFunction: "ease", // 滑动方式
        speed: 300, // 滑动时间
        pagination: false,
      },
      listData: [],
    };
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
    this.getCollectionList();
    this.getBook();
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        // 页面进入后台，停止滚动
        this.$refs.slider.$emit('autoplayStop')
        this.$refs.sliders.$emit('autoplayStop')
      } else {
        // 页面重新激活，重新启动滚动
        this.$refs.slider.$emit('autoplayStart',5000)
        this.$refs.sliders.$emit('autoplayStart',5000)
      }
    },
    async getCollectionList() {
      const res = await getCollection();
      if (res.status == 200) {
        let arr = res.data.records.map((item) => {
          item.book_count = item.book_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return item;
        });
        this.sliceMethods(arr, "top");
        this.endVal = res.data.total;
      }
    },
    async getBook() {
      const res = await getBook();
      if (res.status == 200) {
        const files = require.context("@/assets/img/colleges/", false, /\.png$/);
        const filedata = files.keys();
        let datas = res.data.records.map((item, index) => {
          item.index = index;
          if (filedata.indexOf(`./${item.logo}.png`) == -1) {
            item.status = false;
          } else {
            item.status = true;
          }

          return item;
        });
        this.sliceMethods(datas, "bottom");
      }
    },
    sliceMethods(datas, type) {
      for (let i = 0; i < datas.length; i += 8) {
        if (type == "bottom") {
          this.listData.push(datas.slice(i, i + 8));
        } else {
          this.someListOn.push(datas.slice(i, i + 8));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bit {
  width: 25%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  margin-left: 45px;
  // position: relative;

  .topBox {
    // padding: 1.5px;
    height: 53%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0.1px 0.1px 2px 0.2px #1a52b3;
    margin-bottom: 30px;
    .topBox2 {
      padding: 12px;
      // height: 100%;

      .topBox_fons {
        font-size: 22px;
        font-weight: 600;
        text-align: left;
        // height: 8%;
        color: #089ee5;
        // margin-bottom:5% ;
        .fons1 {
          font-size: 25px;
          color: #fe6c2b;
          font-weight: 900;
        }
      }
      .item_list {
        margin-bottom: 11px;
        display: flex;
        align-content: space-between;
        .span_name {
          display: inline-block;
          width: 78%;
          height: 26px;
          font-size: 15px;
          line-height: 26px;
          background: linear-gradient(to right, #0e4065, #01284b);
          text-align: left;
          border-radius: 13px;
          border: 1px solid #656b77;
          padding-left: 10px;
        }
        .span_number {
          font-size: 15px;
          flex: 1;
          text-align: right;
          padding-top: 7px;
          margin-right: 5px;
        }
      }
    }
  }
  .botBox {
    // position: sticky;
    // bottom: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0.1px 0.1px 2px 0.2px #1a52b3;
    // position: relative;
    .botBox2 {
      padding: 15px;

      .botBox_fons {
        font-size: 22px;
        text-align: left;
        font-weight: 600;
        color: #089ee5;
        margin-bottom: 10px;
      }
      .botBox_list {
        height: 240px;
        width: 100%;
      }
    }
  }
}
::v-deep.swiper-container-horizontal > * > .slider-wrapper {
  align-items: normal !important;
}
.slider-item {
  display: block;
}
</style>
