
    //导入axios 和 qs 插件
    import axios from "axios"
    import qs from "qs"
    
    //配置全局公共域名

    const baseURL = process.env.NODE_ENV === "production" ? "https://shutuapi.readerstar.com.cn" : "http://shutu_apis.readerstar.cn";

    
    //创建axios实例 
    let request = axios.create({
        baseURL:baseURL,    //赋值公共域名
        timeout:5000,       //设置延迟时间（单位：毫秒）
        // headers: {
        //     get:{'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'}
        // }
    })
    
    
    //拦截request的发送请求和响应请求，并做一定的配置
    request.interceptors.request.use(
    
        //拦截发送请求，并给请求头信息headers加上token令牌
        config=>{
            config.headers.token = localStorage.getItem("token");
            return config
        },
        err=>{
            Promise.reject(err).catch(error => {console.log(error);})
        }
    )
    request.interceptors.response.use(
    
        //拦截响应请求 ， 这里直接返回数据
        res=>{
            return res;
        },
        err=>{
            Promise.reject(err).catch(error => {console.log(error);})
        }
    )
    
    
    //给axios实例对象request添加postURL方法
    request.postURL = function(url,data,option={}){
        //返回一个promise，实现异步处理
        return new Promise(function(resolve,reject){
            request({
                url:url,
                method:"POST",
                data:qs.stringify(data),
                ...option,
                headers:{"Content-Type":"application/x-www-form-urlencoded; charset=UTF-8",...option.headers}
            })
            .then(res=>resolve(res))
            .catch(err=>reject(err))
        })
    }
    
    
    //导出request
    export default request;