<template>
  <div class="bit">
    
    <div class="jiuming" style="">
    <div class="topBox">
      <div class="topBox2">
        <div class="topBox_fons">
          成员馆总读者数
          <div class="fons1"><rightNum :val="val"></rightNum></div>
        </div>
      </div>
    </div>
    <div class="topBox">
      <div class="topBox2">
        <div class="topBox_fons">成员馆读者数</div>
        <slider ref="sliders" :options="options">
          <slideritem v-for="(item, index) in tableData" :key="index">
            <rightTable :tableData="item"></rightTable>
          </slideritem>
        </slider>
      </div>
    </div>
    </div>
    <div class="botBox">
      <div class="botBox2">
        <div class="botBox_fons">读者最喜欢的文献借阅量排行(日更新)</div>
        <div class="botBox_list">
          <slider ref="slider" :options="optionsTwo">
            <slideritem v-for="(item, index) in listData" :key="index">
              <rightBottom :listData="item" :isValue="isValue"></rightBottom>
            </slideritem>
          </slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider";
import rightNum from "./rightCld/rightNum.vue";
import rightTable from "./rightCld/rightTable.vue";
import rightBottom from "./rightCld/rightBottom.vue";

import { getUser, getBookRanking } from "@/api/api";

export default {
  components: {
    slider,
    slideritem,
    rightNum,
    rightTable,
    rightBottom,
  },
  data() {
    return {
      isValue: false,
      val: 0,
      options: {
        currentPage: 0,
        loop: true,
        thresholdTime: "400",
        autoplay: "5000",
        speed: 300,
        pagination: false,
      },
      optionsTwo: {
        currentPage: 0, //当前页码
        thresholdDistance: 500, //滑动判定距离
        thresholdTime: 100, //滑动判定时间
        autoplay: 5000, //自动滚动[ms]
        loop: true, //循环滚动
        direction: "vertical", //方向设置，垂直滚动
        loopedSlides: 1, //无限滚动前后遍历数
        slidesToScroll: 1, //每次滑动项数
        timingFunction: "ease", // 滑动方式
        speed: 300, // 滑动时间
        pagination: false,
      },
      config: {
        // header: ['列1', '列2', '列3'],
        data: [
          ['<i class="iconfont icon-jiangbei4" style="color: red"></i>', "未代皇帝薄仪的生死时刻"],
          ['<i class="iconfont icon-jiangbei2"></i>', "中日交流标准日本语同步辅导与强化训练 初级"],
          ['<i class="iconfont icon-jiangbei3"></i>', "如果这都不算爱"],
          ['<i class="iconfont icon-jiangpai-shi"></i>', "盗墓笔记"],
          ['<i class="iconfont icon-jiangpai-shi"></i>', "有机化学习题解答"],
          ['<i class="iconfont icon-jiangpai-shi"></i>', "国学概论"],
          ['<i class="iconfont icon-jiangpai-shi"></i>', "有机化学习题解答"],
          ['<i class="iconfont icon-jiangpai-shi"></i>', "国学概论"],
          ['<i class="iconfont icon-jiangpai-shi"></i>', "行9列2"],
          ['<i class="iconfont icon-jiangpai-shi"></i>', "行10列3"],
        ],
        // index: true,
        // columnWidth: [50]
        oddRowBGC: "#0e4164",
        evenRowBGC: "#082c73",
        rowNum: 8,
        hoverPause: true,
        columnWidth: [50, 280, 80],
        align: ["center", "left"],
      },
      tableData: [],
      tableDataTw: [],
      tableDataTh: [],
      tableDataFo: [],
      tableDataFi: [],
      tableDataSi: [],
      indexs: 10,
      listData: [],
      listDataTw: [],
    };
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
    this.getTotal();
    this.getUserList();
    this.getBookRanking();
    // setInterval(() => {
    //   // this.getTotal();
    // }, 120000);
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        // 页面进入后台，停止滚动
        this.$refs.slider.$emit('autoplayStop')
        this.$refs.sliders.$emit('autoplayStop')
      } else {
        // 页面重新激活，重新启动滚动
        this.$refs.slider.$emit('autoplayStart',5000)
        this.$refs.sliders.$emit('autoplayStart',5000)
      }
    },
    async getUserList() {
      const res = await getUser();
      if (res.status == 200) {
        const files = require.context("@/assets/img/colleges/", false, /\.png$/);
        const filedata = files.keys();
        let datas = res.data.records.map((item) => {
          if (filedata.indexOf(`./${item.logo}.png`) == -1) {
            item.status = false;
          } else {
            item.status = true;
          }

          return item;
        });
        for (let i = 0; i < datas.length; i += 6) {
          this.tableData.push(datas.slice(i, i + 6));
        }
      }
    },
    async getTotal() {
      const res = await getUser();
      if (res.status == 200) {
        this.val = `0${res.data.total}`;
      }
    },
    async getBookRanking() {
      const res = await getBookRanking();
      if (res.status == 200) {
        res.data.records.forEach((res, index) => {
          res.index = index;
        });
        for (let i = 0; i < res.data.records.length; i += 8) {
          this.listData.push(res.data.records.slice(i, i + 8));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bit {
  width: 30%;
  // margin: 0 45px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  // position: relative;
.jiuming{
  width: 100%; 
  height: 53%; 
  margin-bottom: 30px; 
  display: flex; 
  flex-direction: column; 
  justify-content: space-between;

  .topBox {
    width: 90%;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0.1px 0.1px 2px 0.2px #1a52b3;
    .topBox2 {
      padding: 10px;
      .topBox_fons {
        font-size: 22px;
        font-weight: 600;
        text-align: left;
        color: #089ee5;
        .fons1 {
          text-align: center;
        }
      }
    }
  }
}
  
  .botBox {
    // position: sticky;
    // bottom: 10px;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 1.5px;
    box-shadow: 0.1px 0.1px 2px 0.2px #1a52b3;
    .botBox2 {
      padding: 15px;
      .botBox_fons {
        font-size: 22px;
        font-weight: 600;
        text-align: left;
        color: #089ee5;
        margin-bottom: 10px;
      }
      .botBox_list {
        width: 100%;
        height: 240px;
      }
    }
  }
}
.slider-item {
  display: block;
}
::v-deep.swiper-container-horizontal > * > .slider-wrapper {
  align-items: normal !important;
}
</style>
