<template>
  <!-- <vue-seamless-scroll :data="listData" class="warp" :class-option="classOption"> -->
  <div class="warp">
    <ul class="item">
      <li v-for="(item, index) in listData" :key="index">
        <div v-if="!isValue">
          <i v-if="item.index == 0" class="iconfont icon-jiangbei4" style="margin-right: 16px"></i>
          <i
            v-else-if="item.index == 1"
            class="iconfont icon-jiangbei2"
            style="margin-right: 16px"
          ></i>
          <i
            v-else-if="item.index == 2"
            class="iconfont icon-jiangbei3"
            style="margin-right: 16px"
          ></i>
          <!-- <i class="iconfont icon-jiangpai-shi" ></i> -->
          <i v-else class="item_i">{{ item.index + 1 }}</i>
        </div>
        <img
          class="img"
          :src="item.status ? require(`@/assets/img/colleges/${item.logo}.png`) : ''"
          alt=""
          v-if="item.status && isValue"
        />
        <span class="title title_lefts" :style="isValue ? '' : 'width:auto'">
          <span class="spanLi" v-if="!item.status && isValue"></span>
          {{ item.lib }}</span
        >
        <span class="titlenumber" v-if="isValue">{{ item.user_count.toLocaleString() }}</span>
      </li>
    </ul>
  </div>
  <!-- </vue-seamless-scroll> -->
</template>

<script>
// import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  name: "Example01Basic",
  components: {
    // vueSeamlessScroll,
  },
  props: {
    listData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isValue: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      classOption: {
        step: 0.5,
        // singleHeight: 35,
        // waitTime: 3000
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.warp {
  height: 240px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  // margin-top: 10px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    li {
      display: block;
      height: 30px;
      line-height: 30px;
      display: flex;
      font-size: 15px;
      background-color: #0e4065;
      padding-left: 15px;
      span {
        border-left: 3px solid #01284b;
        padding-left: 20px;
        text-align: right;
        color: #83ec09;
        margin-right: 10px;
      }
      .title_lefts {
        width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        text-align: left;
      }
    }
    li:nth-child(2n) {
      background-color: #08264c;
    }
    img {
      margin-right: 20px;
    }
  }
}
.item_i {
  margin: -5px 10px 0 -1px;
  display: inline-block;
  font-style: normal;
  line-height: 20px;
  // padding: auto;
  width: 20px;
  text-align: center;
  height: 20px;
  font-size: 8px;
  border-radius: 50%;
  background-color: #0a71c5;
}
.spanLi {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 50px;
}
.img {
  width: 20px;
  height: 20px;
  margin: auto;
}
.titlenumber {
  flex: 1;
  text-align: right;
}
</style>
