<template>
  <div id="app">
      <div class="background">
        <HeaderCom></HeaderCom>
        <div class="cont_lib">
          <ComtentLeft></ComtentLeft>
          <ContentCenter></ContentCenter>
          <ContentRight></ContentRight>
        </div>
      </div>
  </div>
</template>

<script>
import ComtentLeft from '@/components/ContentLeft.vue'
import ContentRight from '@/components/ContentRight.vue'
import ContentCenter from '@/components/ContentCenter.vue'
import HeaderCom from '@/components/HeaderCom.vue'
export default {
  name: 'APP',
  components: { ComtentLeft, ContentRight, ContentCenter, HeaderCom }
}
</script>

<style lang="scss">
@import url('./assets/scss/reset.scss');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
}
html {
  overflow-y: scroll;
}
:root {
  overflow-y: auto;
  overflow-x: hidden;
}
:root body {
  position: absolute;
}
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(../src/assets/img/bg.jpg);
  color: #ccc;
}
.cont_lib{
  // height: 80%;
  display: flex;
  justify-content: space-around;
  padding: 5px;
}
</style>
